import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { HelperService } from "../../core/services/helpers.service/helpers.service";
import { AnalyticsService } from "../../core/services/analytics.service/analytics.service";

@Component({
  selector: 'app-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() article: any;
  @Input() cssClass: string = '';
  @Input() segment: string;
  @Input() analyticsComponent: string;
  @Input() homeHub: boolean = false;
  @Input() authorBlogLink: boolean = false;
  @Input() homeBlogURL: string = '';

  currentLanguage: string;

  constructor(
    private readonly helpers: HelperService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.currentLanguage = this.helpers.getLanguage();
  }

  public getCssClass(): string {
    if(this.cssClass) {
      return `${this.cssClass}-card`;
    }

    return 'card';
  }

  public get articleImage(): string {
    if (!this.article.page_properties || !this.article.page_properties.thumb_image) {
      return '';
    }
    return this.article.page_properties.thumb_image.path;
  }

  public get blogName(): string {
    if (!this.article.template_selection) {
      return '';
    }
    return this.article.template_selection[0].header.nome_do_blog;
  }

  public get authorName(): string {
    if(!this.article.post_author) {
      return '';
    }
    return this.article.post_author[0].title;
  }

  public cropText(text: string) {
    if (this.cssClass) {
      return this.helpers.cropText(text, 200);
    }
    return this.helpers.cropText(text, 80);
  }

  public sendAnalyticsEvent(): void  {
    const action = `${this.segment}:${this.analyticsComponent}`;
    this.analyticsService.trackEvent({
      component: this.analyticsComponent,
      action,
      type: 'BTN',
      title: this.article.title,
      detail: `btn:${this.segment}:${this.analyticsComponent}:${this.article.title}`,
    });
  }
}