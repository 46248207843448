<div class="search__container">
  <ng-container *ngIf="isMobile">
    <button class="search__mobile-button" (click)="showModal()" aria-label="buscar" #buttonOpenModal><ids-icon size="M" aria-hidden="true" contrast="onBrand">busca_outline</ids-icon></button>
  </ng-container>

  <ng-container *ngIf="!isMobile">
    <ids-input-search class="search__form">
      <input idsInput name="term" id="term" [formControl]="term" placeholder="{{ 'SEARCH.PLACEHOLDER' | translate }}" (keydown)="searchByEnter($event)" />
    </ids-input-search>
  </ng-container>

  <div class="search__loading" role="status" aria-live="polite" aria-atomic="true">
    <span *ngIf="isSearching" class="progress" aria-hidden="true"></span>
    <span *ngIf="isSearching" class="hidden-element">{{ 'SEARCH.SEARCHING' | translate }}</span>
    <span *ngIf="showLTMessage && (searchResult && (searchResult.articles.total || searchResult.tags.length))" class="hidden-element">
      {{ 'SEARCH.SEARCH_COMPLETE' | translate }}, 
      <ng-container *ngIf="searchResult.articles.total && searchResult.tags.length">        
        {{ 'SEARCH.FOUND_ALL_SR' | translate:{tags: searchResult.tags.length, articles: searchResult.articles.total} }}
      </ng-container>

      <ng-container *ngIf="!searchResult.articles.total && searchResult.tags.length">
        {{ 'SEARCH.FOUND_TAGS_SR' | translate:{tags: searchResult.tags.length} }}
      </ng-container>
      
      <ng-container *ngIf="searchResult.articles.total && !searchResult.tags.length">
        {{ 'SEARCH.FOUND_ARTICLES_SR' | translate:{articles: searchResult.articles.total} }}
      </ng-container>
    </span>
    <div *ngIf="showLTMessage && (!searchResult || (!searchResult.articles.total && !searchResult.tags.length))" class="hidden-element">{{ 'SEARCH.SEARCH_COMPLETE_WITHOUT_RESULTS' | translate:{term: term.value} }}</div>
  </div>

  <div class="search__modal" [ngClass]="{'has-language-menu': hasMenuLanguage}" aria-label="Resultados da busca" *ngIf="(isMobile && show) || (searchResult && show)" cdkTrapFocus [cdkTrapFocusAutoCapture]="isMobile">
    <div class="container">
      <span class="search__close">
        <button class="search__mobile-button" #closeModalButton (click)="closeModal()" aria-label="fechar busca"><ids-icon size="L" aria-hidden="true">fechar</ids-icon></button>
      </span>

      <ng-container *ngIf="isMobile">
        <div class="ids-px-6">
          <ids-input-search class="search__form">
            <input idsInput name="term" id="term" [formControl]="term" placeholder="{{ 'SEARCH.PLACEHOLDER' | translate }}" (keydown)="searchByEnter($event)" />
          </ids-input-search>
        </div>
      </ng-container>

      <div *ngIf="searchResult" class="search__result">
        <ng-container *ngIf="searchResult.tags.length">
          <div role="region" aria-label="Tags">
            <p class="search__result-title">Tags <span>( {{searchResult.tags.length}} {{ "SEARCH.FOUND_FEMALE" | translate:{items: searchResult.tags.length} }} )</span></p>
            <ul role="list" class="search__tag-list">
              <li role="listitem" *ngFor="let tag of searchResult.tags">
                <a href="{{ getSearchUrl() }}?t={{tag}}" class="search__tag">{{ tag }}</a>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="searchResult.articles.total">
          <div role="region" [attr.aria-label]="'SEARCH.RESULTS.ARTICLE' | translate">
            <p class="search__result-title">{{ 'SEARCH.RESULTS.ARTICLE' | translate }} <span>({{ searchResult.articles.total }} {{ "SEARCH.FOUND_MALE" | translate:{items: searchResult.articles.total} }})</span></p>
            <ul role="list" class="search__article-list">
              <ng-container *ngFor="let article of searchResult.articles.entries; let i = index">
                <li role="listitem">
                  <span [innerHTML]="boldString(cropText(article.title))" role="text" id="result-article-{{ i }}"></span>
                  <a href="{{article.url}}" [attr.aria-describedby]="'result-article-' + i">{{ 'SEARCH.RESULTS.SEE_ARTICLE' | translate }} <ids-icon size="S" aria-hidden="true">seta_direita</ids-icon></a>
                </li>
              </ng-container>
            </ul>
            <a class="search__load-more" href="{{ getSearchUrl() }}?s={{term.value}}" *ngIf="searchResult.articles.length > 6"  [attr.aria-label]="'SEARCH.RESULTS.BUTTON_ARIALABEL' | translate:{total: searchResult.articles.total}" role="button">{{ 'SEARCH.RESULTS.BUTTON_TEXT' | translate }}</a>
          </div>
        </ng-container>

        <ng-container *ngIf="!searchResult.tags.length && !searchResult.articles.total">
          <p role="text">{{ 'SEARCH.NOT_FOUND' | translate }} <strong>"{{ term.value }}"</strong>.</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>