import { Injectable } from '@angular/core';
import { HelperService } from '../helpers.service/helpers.service';

interface EventGA {
  type: string;
  component: string;
  title?: string;
  text?: string;
  action?: string;
  category?: string;
  detail?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private helper: HelperService) {}

  trackEvent(eventGa: EventGA): void {
    const itauDigitalAnalytics: { track: ({}) => void } = window['ItauDigitalAnalytics' as keyof typeof window] || null;
    if (!itauDigitalAnalytics) { return; }

    const { type, component, title, text, action, category, detail } = eventGa;
    let label = `${type}:${component}`;
    let pageNameGA4 = window.location.pathname.substring(1, 101).toLocaleLowerCase();
    const detailGA4 = this.helper.skewerCase(detail as string, true).replace(/[.,\/#!$%\^&\*;{}=\_`~()]/g, '');
    
    if (title) { label = `${label}:${title}`; }
    if (text) { label = `${label}:${text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')}`; }

    const analytics = {
      page: {
        nome: `IT:NL:IN:BLOG:${this.formatPageName()}`,
        url: document.location.href,
      },
      custom: {
        squadresponsaveltag: 'Martech/Produtos'
      },
      events: {
        category: category || 'Clique',
        action: action || 'objeto clicado',
        label: this.helper.pascalCase(label),
      },
      rule: 'customLink',
      event_name: 'click',
      parameters: {
        custom_path: `/${pageNameGA4}`,
        implementation_team: 'produtos-martech:martech',
        detail: detailGA4,
    }
  };
    itauDigitalAnalytics.track({ analytics });
  }

  formatPageName(): string {
    const path = document.location.pathname.split('/').filter(Boolean);
    const pageName = path.map((item) => {
      return item.split('-').map((innerItem) => innerItem.charAt(0).toUpperCase() + innerItem.slice(1)).join('');
    }).join(":");

    return pageName;
  }
}
