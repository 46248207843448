<div class="most-recents" *ngIf="filteredArticles.length">
  <div class="most-recents__header-hub">
    <h2 class="most-recents__overwrite">{{ 'RECENT_POSTS_TITLE_HUB' | translate }}</h2>
  </div>
<div class="most-recents__list" *ngIf="filteredArticles.length">
  <ng-container *ngFor="let item of filteredArticles; let isFirst = first; let i = index">
    <app-card *ngIf="i < 5" 
      [ngClass]="{'first': isFirst}"
      [article]="item"
      [homeHub]="true"
      [authorBlogLink]="true"
      [homeBlogURL]="'/'+ item.template_selection[0].segment"
      [segment]="'hub'"
      [analyticsComponent]="'MicrofrontRecentPostsHub'"
    >
    </app-card>
  </ng-container>
</div>