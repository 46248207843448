<div class="skeleton container">

    <ng-container *ngIf="!insideLoading">
        <!-- <ngx-skeleton-loader count="1" appearance="line" [theme]="bannerSkeleton"></ngx-skeleton-loader>
    
        <ngx-skeleton-loader count="4" class="skeleton__desktop-visible" appearance="line" [theme]="smallTextSkeleton"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="2" class="skeleton__mobile-visible" appearance="line"></ngx-skeleton-loader> -->

        <div class="skeleton__text-center">
            <ngx-skeleton-loader count="1" class="skeleton__mobile-visible" [theme]="separatorSkeleton" appearance="line"></ngx-skeleton-loader>
        </div>

        <ngx-skeleton-loader count="1" class="skeleton__mobile-visible" [theme]="subtitleSkeleton" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" class="skeleton__mobile-visible" [theme]="titleSkeleton" appearance="line"></ngx-skeleton-loader>
        
        <ngx-skeleton-loader class="skeleton__mobile-visible" count="2" appearance="line" [theme]="smallTextSkeleton"></ngx-skeleton-loader>

    </ng-container>
    
    <div class="skeleton__highlight">
        <div class="skeleton__highlight--first">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="highlightCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__highlight--second">
            <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
        </div>
    </div>
    <br><br>

    <ngx-skeleton-loader count="1" appearance="line" [theme]="smallTextSkeleton"></ngx-skeleton-loader>
    <div class="skeleton__read">
        <div class="skeleton__read--first skeleton__desktop-visible">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="highlightCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__read--second">
            <ngx-skeleton-loader count="2" appearance="line" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
        <div class="skeleton__read--third skeleton__desktop-visible">
            <ngx-skeleton-loader count="2" appearance="line" [theme]="smallCardSkeleton"></ngx-skeleton-loader>
        </div>
    </div>
</div>