import { Component, OnInit, Input} from '@angular/core';
@Component({
  selector: 'app-featured-post',
  templateUrl: './featured-post.component.html'
})
export class FeaturedPostComponent implements OnInit {
  @Input() article: any;
  @Input() segment: string;

  ngOnInit(): void {}
}