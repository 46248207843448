import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum languages {
  pt = 'pt-br',
  en = 'en-us',
  es = 'es-es'
};

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private readonly translate: TranslateService
  ) {}

  replaceAccents(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  cropText(text: string, limit: number): string {
    return text.length > limit ? text.slice(0, limit) + ' [...]' : text;
  }

  pascalCase(str: string){
    const fc = this.replaceAccents;
    return str.split(':').map((c) => {
      c = fc(c);
      return c.charAt(0).toUpperCase() + c.replace(/\s(.)/g, (a) => {
        return a.toUpperCase();
      }).replace(/\s/g, '').replace(/^(.)/, (b) => {
        return b.toLowerCase();
      }).slice(1);
    }).join(':');
  }

  getLanguage(): languages {
    const lang = this.translate.currentLang;
    return languages[lang as keyof typeof languages] || languages.pt;
  }

  stripString(str: string, cleanString: boolean) {
    var wm = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ",
      nmw = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr",
      newStr = "",
      found = false;
  
    for (var i = 0; i < str.length; i++) {
      found = false;
  
      for (var a = 0; a < wm.length; a++) {
        if (str.substr(i, 1) == wm.substr(a, 1)) {
          newStr += nmw.substr(a, 1);
          found = true;
          break;
        }
      }
  
      if (found == false) {
        newStr += str.substr(i, 1);
      }
    }
  
    if (cleanString) {
      return newStr.trim().replace(/[^\w\s]/gi, "");
    } else {
      return newStr.trim().replace(/[^\w\s:\-\/]/gi, "");
    }
  }

  skewerCase(str: string, cleanString: boolean) {
    let items = [...new Set(str.split(':'))];
    items = items.map((item) => {
      return this.stripString(
        item.substring(0, 100).replace(/[A-Z]/g,
          (letter, index) => {
            return index > 0 ? ` ${letter.toLowerCase()}` : letter.toLowerCase();
          }
        ), cleanString)
      .replace(/\s+/gi, "-");
    });
    return items.join(':');
  }
}
