<section class="ids-card {{getCssClass()}}__container" *ngIf="article">
  <div class="{{ getCssClass() }}">
    <div class="{{ getCssClass() }}__header">
      <img aria-hidden="true" class="{{ getCssClass() }}__img"
        [src]="articleImage"
        [alt]="'CARD.IMAGE' | translate">
    </div>

    <div class="{{ getCssClass() }}__content">
      <div class="{{ getCssClass() }}__titles">

        <ng-container *ngIf="cssClass !== 'featured'; else h2Block">
          <h3 class="{{ getCssClass() }}__title">
            <a class="{{ getCssClass() }}__link" (click)="sendAnalyticsEvent()" [href]="article.url" [target]="homeHub ? '_blank' : '_self'"> {{ article.title }} </a>
          </h3>  
        </ng-container>
        <ng-template #h2Block>
          <h2 class="{{ getCssClass() }}__title">
            <a class="{{ getCssClass() }}__link" (click)="sendAnalyticsEvent()" [href]="article.url"> {{ article.title }} </a>
          </h2>
        </ng-template>
        <p class="{{ getCssClass() }}__subtitle"> {{ cropText(article.description || '') }}</p>
      </div>
      <div class="{{ getCssClass() }}__footer">
        <p class="{{ getCssClass() }}__author">
          <span>{{ authorName }}</span>
          {{ 'CARD.AT' | translate }}
          <span *ngIf="!homeHub || !authorBlogLink">{{ blogName }}</span>
          <a class="ids-link" *ngIf="homeHub && authorBlogLink" [href]="homeBlogURL" target="_blank">{{ blogName }}</a>
        </p>
        <div class="{{ getCssClass() }}__time-bookmark">
          {{ article.publication_date | date:'dd MMM YYYY':'':currentLanguage }} • {{ article.reading_time }} {{ 'CARD.MIN_READING' | translate }}
          <app-bookmark-article [segment]="segment" [homeHub]="homeHub" [articleUid]="article.uid" [articleTitle]="article.title"></app-bookmark-article>
        </div>
      </div>
    </div>
  </div>
</section>
