<app-menu-categories [segment]="segment" (clickEvent)="filterByCategory($event)"></app-menu-categories>

<div *ngIf="!loading" class="ids-container">
  <app-featured-post [article]="featuredArticle" [segment]="segment" *ngIf="featuredArticle"></app-featured-post>
  <app-post-list [items]="items" [segment]="segment" *ngIf="items.length"></app-post-list>
</div>
<ng-container *ngIf="loading">
  <div class="ids-container">
    <app-featured-post-skeleton></app-featured-post-skeleton>
  </div>
</ng-container>
