import { Component, Input, OnInit } from '@angular/core';
import { map, take, zipWith } from 'rxjs/operators';
import { ContentstackService } from '../../core/services/contentstack/contentstack.service';
import { Article } from '../../models/article';
import { CategoryType, DEFAULT_CATEGORY } from './../../shared/menu-categories/menu-categories.component';

@Component({
  selector: 'blog-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Input() segment: string = '';

  public loading = false;
  public items: any[]= [];
  public category: CategoryType = DEFAULT_CATEGORY;
  public featuredArticle: any = null;

  constructor(
    private readonly service: ContentstackService
  ) { }

  ngOnInit() {
    this.loadArticles();
  }

  filterByCategory(category: CategoryType): void {
    this.category = category;
    this.items = [];
    this.loadArticles();
  }

  private loadArticles(): void {
    this.loading = true;
    this.service.getContentType(this.segment);
    const category = this.category.uid !== DEFAULT_CATEGORY.uid ? this.category : DEFAULT_CATEGORY;


    this.service.featuredArticle(this.segment, category)
      .pipe(
        zipWith(this.service.home(this.segment, category)),
        take(1),
        map(this.setArticles.bind(this))
      )
    .subscribe()
    .add(() => this.loading = false);
  }

  setArticles([featured, items]: [Article | null, any[]]) {
    if(!items.length) { return; }
    const articles = items[0];

    if (articles.length) {
      if (!featured) {
        this.featuredArticle = articles[0];
        articles.splice(0, 1);
      }

      if (featured) {
        const featuredIndex = articles.findIndex((article: Article) => (
          featured.uid === article.uid
        ));
        this.featuredArticle = featured;
        articles.splice(featuredIndex, 1);
      }
    }

    this.items = articles;
  }
}
