import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { trigger, state, transition, style, animate } from '@angular/animations';

import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig, defaultToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('default', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 0 }), animate('{{ fadeIn }}ms')]),
      transition(
        'default => closing',
        animate('{{ fadeOut }}ms', style({ opacity: 0 })),
      ),
    ])
  ],
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = 'default';
  icon: string;
  type: string;
  isWindows: boolean;
  open = true;

  private duration: number;
  private intervalId: any;

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {
    this.type = data.type || null;
    this.duration = data.duration || 3000;
    this.icon = data.icon ? data.icon : '';
    this.toastConfig = defaultToastConfig;
  }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', this.duration);
    this.isWindows = this.getOS() === 'Windows';
    console.log('entrou');
    window.addEventListener('keyup', (event: KeyboardEvent) => {
      const key = String(event.key).toLocaleLowerCase();
      if (key === 'escape') {
        this.close();
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.open = false;
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  private getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }
}

type ToastAnimationState = 'default' | 'closing';
