<div class="post-list" *ngIf="articles.length">
  <div class="post-list__header">
    <div>
      <h2 class="post-list__overwrite">{{ 'MOST_RECENT_TITLE' | translate }}</h2>
      <a [href]="getMostRecentURL()" (click)="sendAnalyticsEvent()"
        [attr.aria-label]="'SEE_ALL_ARTICLES' | translate">
        {{ "SEE_ALL" | translate }} <i class="ids-icon ids-icon--small" aria-hidden="true">seta_direita</i>
      </a>
    </div>
    <p class="post-list__heading ids-mt-2 ids-mb-7">{{ 'MOST_RECENT_MOBILE_TITLE' | translate }}</p>
  </div>

  <div class="post-list__list" #articlesMostRecent>
    <ng-container *ngFor="let item of articles; let isFirst = first">
      <app-card 
        [homeHub]="false"
        [authorBlogLink]="false"
        [ngClass]="{'first': isFirst}"
        [article]="item"
        [segment]="segment"
        [analyticsComponent]="analyticsComponent">
      </app-card>
    </ng-container>
  </div>
</div>