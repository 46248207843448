import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-featured-post-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonFeaturedPostComponent implements OnInit {

  @Input() insideLoading = false;

  public highlightCardSkeleton = {height: '415px'};
  public bannerSkeleton = {'margin-left': '-50vw', 'margin-right': '-50vw', 'min-width': '100vw',
    right: '50%', left: '50%', height: '75px', display: 'flex'
  };
  public smallTextSkeleton = {width: '100px', 'margin-right': '24px', height: '40px'};
  public smallCardSkeleton = {height: '200px', width: '100%'};
  public titleSkeleton = {height: '40px', 'margin-top': '16px'};
  public subtitleSkeleton = {height: '22px', 'margin-top': '16px', width: '30%'};
  public separatorSkeleton = {height: '15px', width: '50px', 'text-align': 'center', margin: '32px 0'};

  constructor() { }

  ngOnInit(): void {
  }

}
