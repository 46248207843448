<section class="container">
  <h2 class="articles__title">{{ 'BOOKMARK_LIST.TITLE' | translate }}</h2>

  <ul class="articles__list" role="list" *ngIf="items.length && !loading">
    <li class="articles__item" role="listitem" #articleItem *ngFor="let item of items">
      <app-card [homeHub]="false" [authorBlogLink]="false" [article]="item" [segment]="segment" [analyticsComponent]="BookMarkListMicrofront"></app-card>
    </li>
  </ul>

  <div *ngIf="!items.length && !loading" class="no-bookmarks">
    <div class="no-bookmarks__image" aria-hidden="true">
      <img src="{{fullUrl}}/bundle/assets/images/bookmark-article/no-bookmarks.svg" tabindex="-1" aria-hidden="true">
    </div>
    <div class="no-bookmarks__text">
      <h3>Oops...</h3>
      <p>{{ 'BOOKMARK_LIST.NOT_FOUND_DESCRIPTION' | translate }}</p>
    </div>
  </div>
    
  <button idsMainButton
    *ngIf="showLoadMore()"
    [disabled]="isLoading"
    (click)="loadMore()" 
    class="load-more"
    role="button"
    aria-label="carregar mais artigos"
  >{{ 'LOAD_MORE' | translate }}</button>

  <div role="alert" class="screen-reader-feedback" *ngIf="items.length && !loading">
    <span aria-live="polite" aria-atomic="true">{{ 'SHOW_ITEMS_MESSAGE' | translate:{show: items.length, total: pagination.count} }}</span>
  </div>
</section>