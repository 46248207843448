import { Component, Input, OnInit, ViewChild} from '@angular/core';
import { ContentstackService, LIMIT } from './../../core/services/contentstack/contentstack.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'blog-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit {
  @Input() segment: string;

  public loadMoreClicked = false;
  public loading = false;
  public items: any[]= [];
  public term: string = '';
  public tag: string = '';
  public pagination = {
    skip: 0,
    limit: LIMIT,
    count: 0
  };

  constructor(
    private readonly service: ContentstackService,
    private readonly translate: TranslateService
  ) { }

  ngOnInit() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    
    this.term = params.get('s') || '';
    this.tag = params.get('t') || '';
    this.loadArticles();
  }

  loadMore(): void {
    this.loadArticles(true);
  }

  showLoadMore(): boolean {
    return this.items.length < this.pagination.count;
  }

  get title(): string {
    if (this.term) {
      return this.translate.instant('SEARCH_LIST.TITLE', { term: this.term });
    }
    return this.tag || '';
  }

  @ViewChild('articleItem') set articleItem(_: Element) {
    if (!this.loading) {
      if (this.pagination.skip > this.pagination.limit) {
        const currentItem = (this.pagination.skip - this.pagination.limit) + 1;
        const itemToFocus = (
          document.querySelector('.articles__item:nth-child(' + currentItem + ') .card__title a')
        ) as HTMLElement;
        itemToFocus.focus();
        window.scrollTo({ top: itemToFocus.offsetTop });
      }
    }
  }

  get subtitle(): string {
    if (this.term) {
      return this.translate.instant('SEARCH_LIST.MOBILE_TITLE_TERM', { term: this.term });
    }
    return this.translate.instant('SEARCH_LIST.MOBILE_TITLE_TAG', { tag: this.tag });
  }

  private loadArticles(showMessage = false): void {
    this.service.getContentType(this.segment);

    if (this.tag) {
      return this.searchByTag(showMessage);
    }

    if (this.term) {
      return this.searchByTerm(showMessage);
    }
  }

  private searchByTerm(showMessage = false): void {
    this.loading = true;
    this.service.search(this.segment, this.term, this.pagination.skip).subscribe(
      (response: any[]) => {
        
        this.items.push(...response[0]);
        this.pagination.skip += LIMIT;
        this.pagination.count = response[1];
        this.loadMoreClicked = showMessage;

        setTimeout(() => {
          this.loadMoreClicked = false;
        }, 500);
      }
    ).add(() => this.loading = false);
  }

  private searchByTag(showMessage = false): void {
    this.loading = true;
    this.service.tagList(this.segment, this.tag, this.pagination.skip).subscribe(
      (response: any[]) => {
        this.items.push(...response[0]);
        this.pagination.skip += LIMIT;
        this.pagination.count = response[1];
        this.loadMoreClicked = showMessage;

        setTimeout(() => {
          this.loadMoreClicked = false;
        }, 500);
      }
    ).add(() => this.loading = false);
  }
}