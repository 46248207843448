import { Injectable } from '@angular/core';
import { Stack } from 'contentstack';
import { Observable, from, map, of, catchError } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { HelperService, languages } from '../helpers.service/helpers.service';
import { CategoryType } from './../../../shared/menu-categories/menu-categories.component';

export const LIMIT = 6

@Injectable({
  providedIn: 'root'
})
export class ContentstackService {
  private stack = Stack(environment.contentstack);

  constructor(
    private readonly helper: HelperService
  ) {
    
  }

  private getPagesToNotSearch(segment: string): string[] {
    const currentLang = this.helper.getLanguage();
    const currentSegmentUrl = segment === 'institucional' ? '/' : `/${segment}/`;
    const items = {
      [languages.en]: ['search', 'see-all', 'bookmarked', 'tags'],
      [languages.es]: ['buscador', 'ver-todo', 'guardados', 'tags'],
      [languages.pt]: ['busca', 'ver-todos', 'salvos', 'tags']
    };

    const urls = [
      `/`,
      `/${segment}`,
      `${currentSegmentUrl}${currentLang}`,
      ...items[currentLang].map((url) => {
        if (currentLang === languages.pt) {
          return currentSegmentUrl + url;
        }
        return `${currentSegmentUrl}${this.helper.getLanguage()}/${url}`;
      })
    ];

    return urls;
  }

  getContentType(name: string) {
    const pagesToNotSearch = this.getPagesToNotSearch(name);
    return this.stack.ContentType(`blog_${name}_posts`).Query()
      .notContainedIn('url', pagesToNotSearch)
      .language(this.helper.getLanguage());
  }

  featuredArticle(segment: string, category?: CategoryType) {
    const query = this.getContentType(segment);
    if (category && category.uid) {
      query.where('baas_categories.uid', category.uid);
    }

    return from(
      query
        .where('featured', true)
        .descending('publication_date')
        .includeReference('post_author')
        .includeReference('template_selection')
        .toJSON()
        .findOne()
    ).pipe(
      catchError(() => of(null))
    );
  }

  search(segment: string, term: string, skip = 0): Observable<any> {
    const regex = `^(.*?(${term})[^$]*)$`;
    const query = this.getContentType(segment);
    const queryTitle = this.getContentType(segment).regex('title', regex, 'i').getQuery();
    const queryDescription = this.getContentType(segment).regex('description', regex, 'i').getQuery();

    return from(
      query
      .or(queryTitle, queryDescription)
      .descending('publication_date')
      .includeReference('post_author')
      .includeReference('template_selection')
      .skip(skip)
      .limit(LIMIT)
      .includeCount()
      .toJSON()
      .find()
    );
  }

  home(segment: string, category?: CategoryType): Observable<any> {
    const query = this.getContentType(segment);

    if (category && category.uid) {
      query.where('baas_categories.uid', category.uid);
    }

    return from(
      query
      .descending('publication_date')
      .includeReference('post_author')
      .includeReference('baas_categories')
      .includeReference('template_selection')
      .limit(LIMIT)
      .includeCount()
      .toJSON()
      .find()
    );
  }

  articlesList(segment: string, skip = 0, category?: CategoryType): Observable<any> {
    const query = this.getContentType(segment);

    if (category && category.uid) {
      query.where('baas_categories.uid', category.uid);
    }

    return from(
      query
      .includeReference('post_author')
      .includeReference('template_selection')
      .includeReference('baas_categories')
      .descending('publication_date')
      .skip(skip)
      .limit(LIMIT)
      .includeCount()
      .toJSON()
      .find()
    );
  }

  tagList(segment: string, tag: string, skip = 0): Observable<any> {
    const query = this.getContentType(segment);

    return from(
      query
      .where('article_tags.container_tags', tag)
      .includeReference('post_author')
      .includeReference('template_selection')
      .descending('publication_date')
      .skip(skip)
      .limit(LIMIT)
      .includeCount()
      .toJSON()
      .find()
    );
  }

  categories(segment: string) {
    const query = this.stack.ContentType(`blog_${segment}_posts`).Query();

    return from(
      query
      .language(this.helper.getLanguage())
      .includeReference('baas_categories')
      .where('title', 'HomePage')
      .descending('created_at')
      .includeCount()
      .toJSON()
      .findOne()
    ).pipe(
      map(response => {
        if (response) {
          if (response.baas_categories) {
            return response.baas_categories;
          }

          return response.categories.categories.map((category: string) => ({ uid: category, title: category, type: 'deprecated' }));
        }

        return [];
      }),
      catchError(() => of([]))
    );
  }

  bookmarks(segment: string, bookmarks: string[], skip = 0): Observable<any> {
    const query = this.getContentType(segment);

    return from(
      query
      .containedIn('uid', bookmarks)
      .includeReference('post_author')
      .includeReference('template_selection')
      .descending('publication_date')
      .skip(skip)
      .limit(LIMIT)
      .includeCount()
      .toJSON()
      .find()
    );
  }

  getContentTypeTemplates() {
    const pagesToNotSearch = this.getPagesToNotSearch('blog_template');
    return this.stack.ContentType('blog_template').Query()
      .notContainedIn('url', pagesToNotSearch)
      .language(this.helper.getLanguage());
  }

  getAllBlogs() {
    const query = this.getContentTypeTemplates();

    return from(
      query
      .includeCount()
      .toJSON()
      .find()
    );
  }
}